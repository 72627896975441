// Colors
$mdb-color-lighten-5: #d0d6e2 !default;
$mdb-color-lighten-4: #b1bace !default;
$mdb-color-lighten-3: #929fba !default;
$mdb-color-lighten-2: #7283a7 !default;
$mdb-color-lighten-1: #59698d !default;
$mdb-color-base: #45526e !default;
$mdb-color-darken-1: #3b465e !default;
$mdb-color-darken-2: #2e3951 !default;
$mdb-color-darken-3: #1c2a48 !default;
$mdb-color-darken-4: #1c2331 !default;

$red-lighten-5: #ffebee !default;
$red-lighten-4: #ffcdd2 !default;
$red-lighten-3: #ef9a9a !default;
$red-lighten-2: #e57373 !default;
$red-lighten-1: #ef5350 !default;
$red-base: #f44336 !default;
$red-darken-1: #e53935 !default;
$red-darken-2: #d32f2f !default;
$red-darken-3: #c62828 !default;
$red-darken-4: #b71c1c !default;
$red-accent-1: #ff8a80 !default;
$red-accent-2: #ff5252 !default;
$red-accent-3: #ff1744 !default;
$red-accent-4: #d50000 !default;

$pink-lighten-5: #fce4ec !default;
$pink-lighten-4: #f8bbd0 !default;
$pink-lighten-3: #f48fb1 !default;
$pink-lighten-2: #f06292 !default;
$pink-lighten-1: #ec407a !default;
$pink-base: #e91e63 !default;
$pink-darken-1: #d81b60 !default;
$pink-darken-2: #c2185b !default;
$pink-darken-3: #ad1457 !default;
$pink-darken-4: #880e4f !default;
$pink-accent-1: #ff80ab !default;
$pink-accent-2: #ff4081 !default;
$pink-accent-3: #f50057 !default;
$pink-accent-4: #c51162 !default;

$purple-lighten-5: #f3e5f5 !default;
$purple-lighten-4: #e1bee7 !default;
$purple-lighten-3: #ce93d8 !default;
$purple-lighten-2: #ba68c8 !default;
$purple-lighten-1: #ab47bc !default;
$purple-base: #9c27b0 !default;
$purple-darken-1: #8e24aa !default;
$purple-darken-2: #7b1fa2 !default;
$purple-darken-3: #6a1b9a !default;
$purple-darken-4: #4a148c !default;
$purple-accent-1: #ea80fc !default;
$purple-accent-2: #e040fb !default;
$purple-accent-3: #d500f9 !default;
$purple-accent-4: #aa00ff !default;

$deep-purple-lighten-5: #ede7f6 !default;
$deep-purple-lighten-4: #d1c4e9 !default;
$deep-purple-lighten-3: #b39ddb !default;
$deep-purple-lighten-2: #9575cd !default;
$deep-purple-lighten-1: #7e57c2 !default;
$deep-purple-base: #673ab7 !default;
$deep-purple-darken-1: #5e35b1 !default;
$deep-purple-darken-2: #512da8 !default;
$deep-purple-darken-3: #4527a0 !default;
$deep-purple-darken-4: #311b92 !default;
$deep-purple-accent-1: #b388ff !default;
$deep-purple-accent-2: #7c4dff !default;
$deep-purple-accent-3: #651fff !default;
$deep-purple-accent-4: #6200ea !default;

$indigo-lighten-5: #e8eaf6 !default;
$indigo-lighten-4: #c5cae9 !default;
$indigo-lighten-3: #9fa8da !default;
$indigo-lighten-2: #7986cb !default;
$indigo-lighten-1: #5c6bc0 !default;
$indigo-base: #3f51b5 !default;
$indigo-darken-1: #3949ab !default;
$indigo-darken-2: #303f9f !default;
$indigo-darken-3: #283593 !default;
$indigo-darken-4: #1a237e !default;
$indigo-accent-1: #8c9eff !default;
$indigo-accent-2: #536dfe !default;
$indigo-accent-3: #3d5afe !default;
$indigo-accent-4: #304ffe !default;

$blue-lighten-5: #e3f2fd !default;
$blue-lighten-4: #bbdefb !default;
$blue-lighten-3: #90caf9 !default;
$blue-lighten-2: #64b5f6 !default;
$blue-lighten-1: #42a5f5 !default;
$blue-base: #2196f3 !default;
$blue-darken-1: #1e88e5 !default;
$blue-darken-2: #1976d2 !default;
$blue-darken-3: #1565c0 !default;
$blue-darken-4: #0d47a1 !default;
$blue-accent-1: #82b1ff !default;
$blue-accent-2: #448aff !default;
$blue-accent-3: #2979ff !default;
$blue-accent-4: #2962ff !default;

$light-blue-lighten-5: #e1f5fe !default;
$light-blue-lighten-4: #b3e5fc !default;
$light-blue-lighten-3: #81d4fa !default;
$light-blue-lighten-2: #4fc3f7 !default;
$light-blue-lighten-1: #29b6f6 !default;
$light-blue-base: #03a9f4 !default;
$light-blue-darken-1: #039be5 !default;
$light-blue-darken-2: #0288d1 !default;
$light-blue-darken-3: #0277bd !default;
$light-blue-darken-4: #01579b !default;
$light-blue-accent-1: #80d8ff !default;
$light-blue-accent-2: #40c4ff !default;
$light-blue-accent-3: #00b0ff !default;
$light-blue-accent-4: #0091ea !default;

$cyan-lighten-5: #e0f7fa !default;
$cyan-lighten-4: #b2ebf2 !default;
$cyan-lighten-3: #80deea !default;
$cyan-lighten-2: #4dd0e1 !default;
$cyan-lighten-1: #26c6da !default;
$cyan-base: #00bcd4 !default;
$cyan-darken-1: #00acc1 !default;
$cyan-darken-2: #0097a7 !default;
$cyan-darken-3: #00838f !default;
$cyan-darken-4: #006064 !default;
$cyan-accent-1: #84ffff !default;
$cyan-accent-2: #18ffff !default;
$cyan-accent-3: #00e5ff !default;
$cyan-accent-4: #00b8d4 !default;

$teal-lighten-5: #e0f2f1 !default;
$teal-lighten-4: #b2dfdb !default;
$teal-lighten-3: #80cbc4 !default;
$teal-lighten-2: #4db6ac !default;
$teal-lighten-1: #26a69a !default;
$teal-base: #009688 !default;
$teal-darken-1: #00897b !default;
$teal-darken-2: #00796b !default;
$teal-darken-3: #00695c !default;
$teal-darken-4: #004d40 !default;
$teal-accent-1: #a7ffeb !default;
$teal-accent-2: #64ffda !default;
$teal-accent-3: #1de9b6 !default;
$teal-accent-4: #00bfa5 !default;

$green-lighten-5: #e8f5e9 !default;
$green-lighten-4: #c8e6c9 !default;
$green-lighten-3: #a5d6a7 !default;
$green-lighten-2: #81c784 !default;
$green-lighten-1: #66bb6a !default;
$green-base: #4caf50 !default;
$green-darken-1: #43a047 !default;
$green-darken-2: #388e3c !default;
$green-darken-3: #2e7d32 !default;
$green-darken-4: #1b5e20 !default;
$green-accent-1: #b9f6ca !default;
$green-accent-2: #69f0ae !default;
$green-accent-3: #00e676 !default;
$green-accent-4: #00c853 !default;

$light-green-lighten-5: #f1f8e9 !default;
$light-green-lighten-4: #dcedc8 !default;
$light-green-lighten-3: #c5e1a5 !default;
$light-green-lighten-2: #aed581 !default;
$light-green-lighten-1: #9ccc65 !default;
$light-green-base: #8bc34a !default;
$light-green-darken-1: #7cb342 !default;
$light-green-darken-2: #689f38 !default;
$light-green-darken-3: #558b2f !default;
$light-green-darken-4: #33691e !default;
$light-green-accent-1: #ccff90 !default;
$light-green-accent-2: #b2ff59 !default;
$light-green-accent-3: #76ff03 !default;
$light-green-accent-4: #64dd17 !default;

$lime-lighten-5: #f9fbe7 !default;
$lime-lighten-4: #f0f4c3 !default;
$lime-lighten-3: #e6ee9c !default;
$lime-lighten-2: #dce775 !default;
$lime-lighten-1: #d4e157 !default;
$lime-base: #cddc39 !default;
$lime-darken-1: #c0ca33 !default;
$lime-darken-2: #afb42b !default;
$lime-darken-3: #9e9d24 !default;
$lime-darken-4: #827717 !default;
$lime-accent-1: #f4ff81 !default;
$lime-accent-2: #eeff41 !default;
$lime-accent-3: #c6ff00 !default;
$lime-accent-4: #aeea00 !default;

$yellow-lighten-5: #fffde7 !default;
$yellow-lighten-4: #fff9c4 !default;
$yellow-lighten-3: #fff59d !default;
$yellow-lighten-2: #fff176 !default;
$yellow-lighten-1: #ffee58 !default;
$yellow-base: #ffeb3b !default;
$yellow-darken-1: #fdd835 !default;
$yellow-darken-2: #fbc02d !default;
$yellow-darken-3: #f9a825 !default;
$yellow-darken-4: #f57f17 !default;
$yellow-accent-1: #ffff8d !default;
$yellow-accent-2: #ffff00 !default;
$yellow-accent-3: #ffea00 !default;
$yellow-accent-4: #ffd600 !default;

$amber-lighten-5: #fff8e1 !default;
$amber-lighten-4: #ffecb3 !default;
$amber-lighten-3: #ffe082 !default;
$amber-lighten-2: #ffd54f !default;
$amber-lighten-1: #ffca28 !default;
$amber-base: #ffc107 !default;
$amber-darken-1: #ffb300 !default;
$amber-darken-2: #ffa000 !default;
$amber-darken-3: #ff8f00 !default;
$amber-darken-4: #ff6f00 !default;
$amber-accent-1: #ffe57f !default;
$amber-accent-2: #ffd740 !default;
$amber-accent-3: #ffc400 !default;
$amber-accent-4: #ffab00 !default;

$orange-lighten-5: #fff3e0 !default;
$orange-lighten-4: #ffe0b2 !default;
$orange-lighten-3: #ffcc80 !default;
$orange-lighten-2: #ffb74d !default;
$orange-lighten-1: #ffa726 !default;
$orange-base: #ff9800 !default;
$orange-darken-1: #fb8c00 !default;
$orange-darken-2: #f57c00 !default;
$orange-darken-3: #ef6c00 !default;
$orange-darken-4: #e65100 !default;
$orange-accent-1: #ffd180 !default;
$orange-accent-2: #ffab40 !default;
$orange-accent-3: #ff9100 !default;
$orange-accent-4: #ff6d00 !default;

$deep-orange-lighten-5: #fbe9e7 !default;
$deep-orange-lighten-4: #ffccbc !default;
$deep-orange-lighten-3: #ffab91 !default;
$deep-orange-lighten-2: #ff8a65 !default;
$deep-orange-lighten-1: #ff7043 !default;
$deep-orange-base: #ff5722 !default;
$deep-orange-darken-1: #f4511e !default;
$deep-orange-darken-2: #e64a19 !default;
$deep-orange-darken-3: #d84315 !default;
$deep-orange-darken-4: #bf360c !default;
$deep-orange-accent-1: #ff9e80 !default;
$deep-orange-accent-2: #ff6e40 !default;
$deep-orange-accent-3: #ff3d00 !default;
$deep-orange-accent-4: #dd2c00 !default;

$brown-lighten-5: #efebe9 !default;
$brown-lighten-4: #d7ccc8 !default;
$brown-lighten-3: #bcaaa4 !default;
$brown-lighten-2: #a1887f !default;
$brown-lighten-1: #8d6e63 !default;
$brown-base: #795548 !default;
$brown-darken-1: #6d4c41 !default;
$brown-darken-2: #5d4037 !default;
$brown-darken-3: #4e342e !default;
$brown-darken-4: #3e2723 !default;

$blue-grey-lighten-5: #eceff1 !default;
$blue-grey-lighten-4: #cfd8dc !default;
$blue-grey-lighten-3: #b0bec5 !default;
$blue-grey-lighten-2: #90a4ae !default;
$blue-grey-lighten-1: #78909c !default;
$blue-grey-base: #607d8b !default;
$blue-grey-darken-1: #546e7a !default;
$blue-grey-darken-2: #455a64 !default;
$blue-grey-darken-3: #37474f !default;
$blue-grey-darken-4: #263238 !default;

$grey-lighten-5: #fafafa !default;
$grey-lighten-4: #f5f5f5 !default;
$grey-lighten-3: #eeeeee !default;
$grey-lighten-2: #e0e0e0 !default;
$grey-lighten-1: #bdbdbd !default;
$grey-base: #9e9e9e !default;
$grey-darken-1: #757575 !default;
$grey-darken-2: #616161 !default;
$grey-darken-3: #424242 !default;
$grey-darken-4: #212121 !default;

$black-base: #000 !default;
$white-base: #fff !default;
$foggy-grey: #4f4f4f !default;

$mdb-colors-1: () !default;
$mdb-colors-1: map-merge(
                (
                        'mdb-color': (
                                'lighten-5': $mdb-color-lighten-5,
                                'lighten-4': $mdb-color-lighten-4,
                                'lighten-3': $mdb-color-lighten-3,
                                'lighten-2': $mdb-color-lighten-2,
                                'lighten-1': $mdb-color-lighten-1,
                                'base': $mdb-color-base,
                                'darken-1': $mdb-color-darken-1,
                                'darken-2': $mdb-color-darken-2,
                                'darken-3': $mdb-color-darken-3,
                                'darken-4': $mdb-color-darken-4
                        ),
                        'red': (
                                'lighten-5': $red-lighten-5,
                                'lighten-4': $red-lighten-4,
                                'lighten-3': $red-lighten-3,
                                'lighten-2': $red-lighten-2,
                                'lighten-1': $red-lighten-1,
                                'base': $red-base,
                                'darken-1': $red-darken-1,
                                'darken-2': $red-darken-2,
                                'darken-3': $red-darken-3,
                                'darken-4': $red-darken-4,
                                'accent-1': $red-accent-1,
                                'accent-2': $red-accent-2,
                                'accent-3': $red-accent-3,
                                'accent-4': $red-accent-4
                        ),
                        'pink': (
                                'lighten-5': $pink-lighten-5,
                                'lighten-4': $pink-lighten-4,
                                'lighten-3': $pink-lighten-3,
                                'lighten-2': $pink-lighten-2,
                                'lighten-1': $pink-lighten-1,
                                'base': $pink-base,
                                'darken-1': $pink-darken-1,
                                'darken-2': $pink-darken-2,
                                'darken-3': $pink-darken-3,
                                'darken-4': $pink-darken-4,
                                'accent-1': $pink-accent-1,
                                'accent-2': $pink-accent-2,
                                'accent-3': $pink-accent-3,
                                'accent-4': $pink-accent-4
                        ),
                        'purple': (
                                'lighten-5': $purple-lighten-5,
                                'lighten-4': $purple-lighten-4,
                                'lighten-3': $purple-lighten-3,
                                'lighten-2': $purple-lighten-2,
                                'lighten-1': $purple-lighten-1,
                                'base': $purple-base,
                                'darken-1': $purple-darken-1,
                                'darken-2': $purple-darken-2,
                                'darken-3': $purple-darken-3,
                                'darken-4': $purple-darken-4,
                                'accent-1': $purple-accent-1,
                                'accent-2': $purple-accent-2,
                                'accent-3': $purple-accent-3,
                                'accent-4': $purple-accent-4
                        ),
                        'deep-purple': (
                                'lighten-5': $deep-purple-lighten-5,
                                'lighten-4': $deep-purple-lighten-4,
                                'lighten-3': $deep-purple-lighten-3,
                                'lighten-2': $deep-purple-lighten-2,
                                'lighten-1': $deep-purple-lighten-1,
                                'base': $deep-purple-base,
                                'darken-1': $deep-purple-darken-1,
                                'darken-2': $deep-purple-darken-2,
                                'darken-3': $deep-purple-darken-3,
                                'darken-4': $deep-purple-darken-4,
                                'accent-1': $deep-purple-accent-1,
                                'accent-2': $deep-purple-accent-2,
                                'accent-3': $deep-purple-accent-3,
                                'accent-4': $deep-purple-accent-4
                        ),
                        'indigo': (
                                'lighten-5': $indigo-lighten-5,
                                'lighten-4': $indigo-lighten-4,
                                'lighten-3': $indigo-lighten-3,
                                'lighten-2': $indigo-lighten-2,
                                'lighten-1': $indigo-lighten-1,
                                'base': $indigo-base,
                                'darken-1': $indigo-darken-1,
                                'darken-2': $indigo-darken-2,
                                'darken-3': $indigo-darken-3,
                                'darken-4': $indigo-darken-4,
                                'accent-1': $indigo-accent-1,
                                'accent-2': $indigo-accent-2,
                                'accent-3': $indigo-accent-3,
                                'accent-4': $indigo-accent-4
                        ),
                        'blue': (
                                'lighten-5': $blue-lighten-5,
                                'lighten-4': $blue-lighten-4,
                                'lighten-3': $blue-lighten-3,
                                'lighten-2': $blue-lighten-2,
                                'lighten-1': $blue-lighten-1,
                                'base': $blue-base,
                                'darken-1': $blue-darken-1,
                                'darken-2': $blue-darken-2,
                                'darken-3': $blue-darken-3,
                                'darken-4': $blue-darken-4,
                                'accent-1': $blue-accent-1,
                                'accent-2': $blue-accent-2,
                                'accent-3': $blue-accent-3,
                                'accent-4': $blue-accent-4
                        ),
                        'light-blue': (
                                'lighten-5': $light-blue-lighten-5,
                                'lighten-4': $light-blue-lighten-4,
                                'lighten-3': $light-blue-lighten-3,
                                'lighten-2': $light-blue-lighten-2,
                                'lighten-1': $light-blue-lighten-1,
                                'base': $light-blue-base,
                                'darken-1': $light-blue-darken-1,
                                'darken-2': $light-blue-darken-2,
                                'darken-3': $light-blue-darken-3,
                                'darken-4': $light-blue-darken-4,
                                'accent-1': $light-blue-accent-1,
                                'accent-2': $light-blue-accent-2,
                                'accent-3': $light-blue-accent-3,
                                'accent-4': $light-blue-accent-4
                        ),
                        'cyan': (
                                'lighten-5': $cyan-lighten-5,
                                'lighten-4': $cyan-lighten-4,
                                'lighten-3': $cyan-lighten-3,
                                'lighten-2': $cyan-lighten-2,
                                'lighten-1': $cyan-lighten-1,
                                'base': $cyan-base,
                                'darken-1': $cyan-darken-1,
                                'darken-2': $cyan-darken-2,
                                'darken-3': $cyan-darken-3,
                                'darken-4': $cyan-darken-4,
                                'accent-1': $cyan-accent-1,
                                'accent-2': $cyan-accent-2,
                                'accent-3': $cyan-accent-3,
                                'accent-4': $cyan-accent-4
                        ),
                        'teal': (
                                'lighten-5': $teal-lighten-5,
                                'lighten-4': $teal-lighten-4,
                                'lighten-3': $teal-lighten-3,
                                'lighten-2': $teal-lighten-2,
                                'lighten-1': $teal-lighten-1,
                                'base': $teal-base,
                                'darken-1': $teal-darken-1,
                                'darken-2': $teal-darken-2,
                                'darken-3': $teal-darken-3,
                                'darken-4': $teal-darken-4,
                                'accent-1': $teal-accent-1,
                                'accent-2': $teal-accent-2,
                                'accent-3': $teal-accent-3,
                                'accent-4': $teal-accent-4
                        ),
                        'green': (
                                'lighten-5': $green-lighten-5,
                                'lighten-4': $green-lighten-4,
                                'lighten-3': $green-lighten-3,
                                'lighten-2': $green-lighten-2,
                                'lighten-1': $green-lighten-1,
                                'base': $green-base,
                                'darken-1': $green-darken-1,
                                'darken-2': $green-darken-2,
                                'darken-3': $green-darken-3,
                                'darken-4': $green-darken-4,
                                'accent-1': $green-accent-1,
                                'accent-2': $green-accent-2,
                                'accent-3': $green-accent-3,
                                'accent-4': $green-accent-4
                        ),
                        'light-green': (
                                'lighten-5': $light-green-lighten-5,
                                'lighten-4': $light-green-lighten-4,
                                'lighten-3': $light-green-lighten-3,
                                'lighten-2': $light-green-lighten-2,
                                'lighten-1': $light-green-lighten-1,
                                'base': $light-green-base,
                                'darken-1': $light-green-darken-1,
                                'darken-2': $light-green-darken-2,
                                'darken-3': $light-green-darken-3,
                                'darken-4': $light-green-darken-4,
                                'accent-1': $light-green-accent-1,
                                'accent-2': $light-green-accent-2,
                                'accent-3': $light-green-accent-3,
                                'accent-4': $light-green-accent-4
                        ),
                        'lime': (
                                'lighten-5': $lime-lighten-5,
                                'lighten-4': $lime-lighten-4,
                                'lighten-3': $lime-lighten-3,
                                'lighten-2': $lime-lighten-2,
                                'lighten-1': $lime-lighten-1,
                                'base': $lime-base,
                                'darken-1': $lime-darken-1,
                                'darken-2': $lime-darken-2,
                                'darken-3': $lime-darken-3,
                                'darken-4': $lime-darken-4,
                                'accent-1': $lime-accent-1,
                                'accent-2': $lime-accent-2,
                                'accent-3': $lime-accent-3,
                                'accent-4': $lime-accent-4
                        ),
                        'yellow': (
                                'lighten-5': $yellow-lighten-5,
                                'lighten-4': $yellow-lighten-4,
                                'lighten-3': $yellow-lighten-3,
                                'lighten-2': $yellow-lighten-2,
                                'lighten-1': $yellow-lighten-1,
                                'base': $yellow-base,
                                'darken-1': $yellow-darken-1,
                                'darken-2': $yellow-darken-2,
                                'darken-3': $yellow-darken-3,
                                'darken-4': $yellow-darken-4,
                                'accent-1': $yellow-accent-1,
                                'accent-2': $yellow-accent-2,
                                'accent-3': $yellow-accent-3,
                                'accent-4': $yellow-accent-4
                        ),
                        'amber': (
                                'lighten-5': $amber-lighten-5,
                                'lighten-4': $amber-lighten-4,
                                'lighten-3': $amber-lighten-3,
                                'lighten-2': $amber-lighten-2,
                                'lighten-1': $amber-lighten-1,
                                'base': $amber-base,
                                'darken-1': $amber-darken-1,
                                'darken-2': $amber-darken-2,
                                'darken-3': $amber-darken-3,
                                'darken-4': $amber-darken-4,
                                'accent-1': $amber-accent-1,
                                'accent-2': $amber-accent-2,
                                'accent-3': $amber-accent-3,
                                'accent-4': $amber-accent-4
                        ),
                        'orange': (
                                'lighten-5': $orange-lighten-5,
                                'lighten-4': $orange-lighten-4,
                                'lighten-3': $orange-lighten-3,
                                'lighten-2': $orange-lighten-2,
                                'lighten-1': $orange-lighten-1,
                                'base': $orange-base,
                                'darken-1': $orange-darken-1,
                                'darken-2': $orange-darken-2,
                                'darken-3': $orange-darken-3,
                                'darken-4': $orange-darken-4,
                                'accent-1': $orange-accent-1,
                                'accent-2': $orange-accent-2,
                                'accent-3': $orange-accent-3,
                                'accent-4': $orange-accent-4
                        ),
                        'deep-orange': (
                                'lighten-5': $deep-orange-lighten-5,
                                'lighten-4': $deep-orange-lighten-4,
                                'lighten-3': $deep-orange-lighten-3,
                                'lighten-2': $deep-orange-lighten-2,
                                'lighten-1': $deep-orange-lighten-1,
                                'base': $deep-orange-base,
                                'darken-1': $deep-orange-darken-1,
                                'darken-2': $deep-orange-darken-2,
                                'darken-3': $deep-orange-darken-3,
                                'darken-4': $deep-orange-darken-4,
                                'accent-1': $deep-orange-accent-1,
                                'accent-2': $deep-orange-accent-2,
                                'accent-3': $deep-orange-accent-3,
                                'accent-4': $deep-orange-accent-4
                        ),
                        'brown': (
                                'lighten-5': $brown-lighten-5,
                                'lighten-4': $brown-lighten-4,
                                'lighten-3': $brown-lighten-3,
                                'lighten-2': $brown-lighten-2,
                                'lighten-1': $brown-lighten-1,
                                'base': $brown-base,
                                'darken-1': $brown-darken-1,
                                'darken-2': $brown-darken-2,
                                'darken-3': $brown-darken-3,
                                'darken-4': $brown-darken-4
                        ),
                        'blue-grey': (
                                'lighten-5': $blue-grey-lighten-5,
                                'lighten-4': $blue-grey-lighten-4,
                                'lighten-3': $blue-grey-lighten-3,
                                'lighten-2': $blue-grey-lighten-2,
                                'lighten-1': $blue-grey-lighten-1,
                                'base': $blue-grey-base,
                                'darken-1': $blue-grey-darken-1,
                                'darken-2': $blue-grey-darken-2,
                                'darken-3': $blue-grey-darken-3,
                                'darken-4': $blue-grey-darken-4
                        ),
                        'grey': (
                                'lighten-5': $grey-lighten-5,
                                'lighten-4': $grey-lighten-4,
                                'lighten-3': $grey-lighten-3,
                                'lighten-2': $grey-lighten-2,
                                'lighten-1': $grey-lighten-1,
                                'base': $grey-base,
                                'darken-1': $grey-darken-1,
                                'darken-2': $grey-darken-2,
                                'darken-3': $grey-darken-3,
                                'darken-4': $grey-darken-4
                        ),
                        'black': (
                                'base': $black-base
                        ),
                        'white': (
                                'base': $white-base
                        )
                ),
                $mdb-colors-1
);

// Full palette of colors
$enable_full_palette: true !default;

// Stylish rgba colors
$stylish-rgba: (
        'rgba-stylish-slight': rgba(62, 69, 81, 0.1),
        'rgba-stylish-light': rgba(62, 69, 81, 0.3),
        'rgba-stylish-strong': rgba(62, 69, 81, 0.7)
);

// Material colors
$primary-color: #4285f4 !default;
$primary-color-dark: #0d47a1 !default;
$secondary-color: #aa66cc !default;
$secondary-color-dark: #9933cc !default;
$default-color: #2bbbad !default;
$default-color-dark: #00695c !default;
$info-color: #33b5e5 !default;
$info-color-dark: #0099cc !default;
$success-color: #00c851 !default;
$success-color-dark: #007e33 !default;
$warning-color: #ffbb33 !default;
$warning-color-dark: #ff8800 !default;
$danger-color: #ff3547 !default;
$danger-color-dark: #cc0000 !default;
$elegant-color: #2e2e2e !default;
$elegant-color-dark: #212121 !default;
$stylish-color: #4b515d !default;
$stylish-color-dark: #3e4551 !default;
$unique-color: #3f729b !default;
$unique-color-dark: #1c2331 !default;
$special-color: #37474f !default;
$special-color-dark: #263238 !default;
$white: #fff;
$black: #000;
$error-color: $red-base !default;

$material-colors: () !default;
$material-colors: map-merge(
                (
                        'primary-color': $primary-color,
                        'primary-color-dark': $primary-color-dark,
                        'secondary-color': $secondary-color,
                        'secondary-color-dark': $secondary-color-dark,
                        'default-color': $default-color,
                        'default-color-dark': $default-color-dark,
                        'info-color': $info-color,
                        'info-color-dark': $info-color-dark,
                        'success-color': $success-color,
                        'success-color-dark': $success-color-dark,
                        'warning-color': $warning-color,
                        'warning-color-dark': $warning-color-dark,
                        'danger-color': $danger-color,
                        'danger-color-dark': $danger-color-dark,
                        'elegant-color': $elegant-color,
                        'elegant-color-dark': $elegant-color-dark,
                        'stylish-color': $stylish-color,
                        'stylish-color-dark': $stylish-color-dark,
                        'unique-color': $unique-color,
                        'unique-color-dark': $unique-color-dark,
                        'special-color': $special-color,
                        'special-color-dark': $special-color-dark
                ),
                $material-colors
);

// Social colors
$fb-color: #3b5998 !default;
$tw-color: #55acee !default;
$gplus-color: #dd4b39 !default;
$yt-color: #ed302f !default;
$li-color: #0082ca !default;
$pin-color: #c61118 !default;
$ins-color: #2e5e86 !default;
$git-color: #333333 !default;
$comm-color: #30cfc0 !default;
$vk-color: #4c75a3 !default;
$drib-color: #ec4a89 !default;
$so-color: #ffac44 !default;
$slack-color: #56b68b !default;
$email-color: #4b515d !default;
$redd-color: #ff4500 !default;
$twitch-color: #6441a4 !default;
$discord-color: #7289da !default;

$social-colors: () !default;
$social-colors: map-merge(
                (
                        'fb': $fb-color,
                        'tw': $tw-color,
                        'gplus': $gplus-color,
                        'yt': $yt-color,
                        'li': $li-color,
                        'pin': $pin-color,
                        'ins': $ins-color,
                        'git': $git-color,
                        'comm': $comm-color,
                        'vk': $vk-color,
                        'dribbble': $drib-color,
                        'so': $so-color,
                        'slack': $slack-color,
                        'email': $email-color,
                        'reddit': $redd-color,
                        'twitch': $twitch-color,
                        'discord': $discord-color
                ),
                $social-colors
);

// MDB buttons colors
$mdb-colors: () !default;
$mdb-colors: map-merge(
                (
                        'primary': $primary-color,
                        'danger': $danger-color,
                        'warning': $warning-color,
                        'success': $success-color,
                        'info': $info-color,
                        'default': $default-color,
                        'secondary': $secondary-color,
                        'elegant': $elegant-color,
                        'unique': $pink-darken-4,
                        'dark-green': $green-darken-2,
                        'mdb-color': $mdb-color-lighten-1,
                        'red': $red-darken-2,
                        'pink': $pink-lighten-1,
                        'purple': $purple-darken-1,
                        'deep-purple': $deep-purple-darken-2,
                        'indigo': $indigo-base,
                        'blue': $blue-darken-2,
                        'light-blue': $blue-accent-1,
                        'cyan': $cyan-base,
                        'teal': $teal-darken-2,
                        'green': $green-darken-2,
                        'light-green': $light-green-base,
                        'lime': $lime-darken-2,
                        'yellow': $yellow-darken-2,
                        'amber': $amber-darken-2,
                        'orange': $orange-darken-2,
                        'deep-orange': $deep-orange-lighten-1,
                        'brown': $brown-base,
                        'grey': $grey-darken-2,
                        'blue-grey': $blue-grey-lighten-1,
                        'dark': $grey-darken-4,
                        'light': $grey-lighten-2,
                        'white': $white-base,
                        'black': $black-base
                ),
                $mdb-colors
);

// Basic colors
$basic: () !default;
$basic: map-merge(
                (
                        'primary': $primary-color,
                        'danger': $danger-color,
                        'warning': $warning-color,
                        'success': $success-color,
                        'info': $info-color
                ),
                $basic
);

$basic-mdb-colors: () !default;
$basic-mdb-colors: map-merge(
                (
                        'primary': $primary-color,
                        'danger': $danger-color,
                        'warning': $warning-color,
                        'success': $success-color,
                        'info': $info-color,
                        'default': $default-color,
                        'secondary': $secondary-color,
                        'dark': $grey-darken-4,
                        'light': $grey-lighten-2
                ),
                $basic-mdb-colors
);

$pagination-colors: () !default;
$pagination-colors: map-merge(
                (
                        'blue': $primary-color,
                        'red': $danger-color,
                        'teal': $default-color,
                        'dark-grey': $special-color,
                        'dark': $elegant-color,
                        'blue-grey': $unique-color,
                        'amber': $amber-darken-4,
                        'purple': $deep-purple-darken-1
                ),
                $pagination-colors
);

$ctbc: () !default;
$ctbc: map-merge(
                (
                        'tabs-cyan': $yellow-base,
                        'tabs-orange': $red-darken-1,
                        'tabs-grey': $white-base,
                        'tabs-pink': $deep-purple-base,
                        'tabs-green': $blue-darken-3,
                        'tabs-primary': $white-base
                ),
                $ctbc
);

$switch-color-bg: $secondary-color !default;
$switch-color-checked-lever-bg: desaturate(
                lighten($secondary-color, 25%),
                25%
) !default;
$switch-color-unchecked-bg: #f1f1f1 !default;
$switch-color-unchecked-lever-bg: #818181 !default;

$switch-colors: () !default;
$switch-colors: map-merge(
                (
                        'bg': $switch-color-bg,
                        'checked-lever-bg': $switch-color-checked-lever-bg,
                        'unchecked-bg': $switch-color-unchecked-bg,
                        'unchecked-lever-bg': $switch-color-unchecked-lever-bg
                ),
                $switch-colors
);

$dropdown-colors: () !default;
$dropdown-colors: map-merge(
                (
                        'primary': $primary-color,
                        'danger': $danger-color-dark,
                        'default': $default-color,
                        'secondary': $secondary-color,
                        'success': $success-color,
                        'info': $info-color,
                        'warning': $warning-color,
                        'dark': map-get($mdb-colors, 'elegant'),
                        'ins': map-get($social-colors, 'ins')
                ),
                $dropdown-colors
);

// Gradients
$gradients: () !default;
$gradients: map-merge(
                (
                        'purple': (
                                'start': #ff6ec4,
                                'end': #7873f5
                        ),
                        'peach': (
                                'start': #ffd86f,
                                'end': #fc6262
                        ),
                        'aqua': (
                                'start': #2096ff,
                                'end': #05ffa3
                        ),
                        'blue': (
                                'start': #45cafc,
                                'end': $indigo-darken-2
                        )
                ),
                $gradients
);

// Gradients RGBA Version
$gradients-rgba: () !default;
$gradients-rgba: map-merge(
                (
                        'purple': (
                                'start': rgba(255, 110, 196, 0.9),
                                'end': rgba(120, 115, 245, 0.9)
                        ),
                        'peach': (
                                'start': rgba(255, 216, 111, 0.9),
                                'end': rgba(252, 98, 98, 0.9)
                        ),
                        'aqua': (
                                'start': rgba(32, 150, 255, 0.9),
                                'end': rgba(5, 255, 163, 0.9)
                        ),
                        'blue': (
                                'start': rgba(69, 202, 252, 0.9),
                                'end': rgba(48, 63, 159, 0.9)
                        )
                ),
                $gradients-rgba
);

$note: () !default;
$note: map-merge(
                (
                        'note-primary': #176ac4,
                        'note-secondary': #58595a,
                        'note-success': #49a75f,
                        'note-danger': #e45460,
                        'note-warning': #c2a442,
                        'note-info': #2492a5,
                        'note-light': #0f0f0f
                ),
                $note
);
