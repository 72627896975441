/* autoprefixer grid: autoplace */
/* You can add global styles to this file, and also import other style files */
@import '../node_modules/@syncfusion/ej2-base/styles/material.scss';
@import '../node_modules/@syncfusion/ej2-angular-navigations/styles/material.scss';
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/material.scss';
@import '../node_modules/@syncfusion/ej2-angular-kanban/styles/material.scss';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.scss';
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.scss';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.scss';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.scss';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.scss';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.scss';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.scss';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.scss';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.scss';
@import '../node_modules/@syncfusion/ej2-angular-pivotview/styles/material.scss';
@import '../node_modules/@syncfusion/ej2-notifications/styles/material.scss';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inplace-editor/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-layouts/styles/material.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '../node_modules/animate.css';
@import '../src/cst_styles/dot-animations.scss';
@import './colors';

body {
    font-family: 'Montserrat', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px !important;
    background-color: #eee;
    min-width: auto !important;
    padding: 0;
    margin: 0;
}

h1 {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 26px !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 800 !important;
    line-height: 28.4px !important;
}

h3 {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 700 !important;
    line-height: 19.4px !important;
}

h4 {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 17px !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 600 !important;
    line-height: 18.4px !important;
}

h5 {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 500 !important;
    line-height: 17.4px !important;
}

h6 {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 400 !important;
    line-height: 16.4px !important;
}

p {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 400 !important;
    line-height: inherit !important;
}

.e-card {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 400 !important;
    line-height: 15px !important;
}

.e-tab-text {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 400 !important;
    line-height: 17px !important;
}

.e-list-text {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 400;
    line-height: 15px;
}

input,
textarea,
label {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 400 !important;
    line-height: 15px !important;
}

button {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 600 !important;
    line-height: 12px !important;
}

.e-error {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 400 !important;
    line-height: 15px !important;
}

.e-succuess {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 400 !important;
    line-height: 15px !important;
}

.e-table {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 400 !important;
    line-height: 15px !important;
}

blockquote {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 21px !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 400 !important;
    line-height: 30px !important;
}

pre {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 400 !important;
    line-height: 19.5714px !important;
}

strong {
    font-weight: 600 !important;
}

label {
    font-family: 'Montserrat', sans-serif !important;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 20px;
}

.login-card {
    background-color: rgba(229, 228, 255, 0.2);
}

.modal-full-height.modal-bottom > .modal-content {
    position: absolute !important;
    bottom: 0;
}

.view-cascade {
    .mask {
        overflow-y: scroll;
    }
}

.fixed-sn main {
    padding-top: 75px !important;
}

.striked {
    text-decoration: line-through;
}

.table tr td,
.table tr th {
    font-size: 12px !important;
}

.table tr td,
.table tr th {
    padding: 5px !important;
}

.card.card-cascade .view.gradient-card-header {
    padding: 1rem 1rem;
    text-align: center;
}

.card.card-cascade h3,
.card.card-cascade h4 {
    margin-bottom: 0;
}

.blue-gradient {
    background: -webkit-linear-gradient(50deg, #45cafc, #303f9f);
    background: linear-gradient(40deg, #45cafc, #4285f4);
}

.modal {
    overflow-y: auto !important;
}

.modal-body {
    margin: 0;
    min-height: 300px;
    max-height: 500px;
    overflow-y: auto;
}

img.tvlogo {
    width: 50px !important;
    height: auto !important;
}

img.avatar-xs {
    width: 30px !important;
    height: 30px !important;
    margin: 5px !important;
}

img.avatar-sm {
    width: 50px !important;
    height: 50px !important;
    margin: 5px !important;
}

img.avatar-md {
    width: 70px !important;
    height: 70px !important;
    margin: 5px !important;
}

img.avatar-lg {
    width: 100px !important;
    height: 100px !important;
    margin: 5px !important;
}

img.avatar-xl {
    width: 250px !important;
    height: 250px !important;
    margin: 5px !important;
}

table tbody tr td .cmnt-image {
    width: 50% !important;
    height: auto !important;
    max-width: 200px !important;
    max-height: 100px !important;
}

.comment_text {
    font-size: 11px !important;
}

.fixedContainer {
    position: fixed;
    top: 23%;
    transform: translate(225%);
}

.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 55px;
    z-index: 1000;
}

.sticky-without-padding {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    z-index: 1000;
}

.sidebar-sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 1000;
}

.sticky-nd {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 177px;
    z-index: 1000;
}

.btn-p-zero {
    padding: 5px 10px !important;
}

.breadcrumb {
    padding: 0.5rem 1.6rem !important;
    margin-bottom: 0.7rem !important;
    background-color: transparent !important;
}

.breadcrumb li {
    font-family: 'Montserrat', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 15px !important;
}

.breadcrumb li span {
    cursor: pointer;
}

.scrollbar-juicy-peach::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-juicy-peach::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-juicy-peach::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(
                    linear,
                    left top,
                    right top,
                    from(#ffecd2),
                    to(#fcb69f)
    );
    background-image: -webkit-linear-gradient(left, #ffecd2 0%, #fcb69f 100%);
    background-image: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
}

.scrollbar-young-passion::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-young-passion::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-young-passion::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(
                    linear,
                    left top,
                    right top,
                    from(#ff8177),
                    color-stop(0%, #ff867a),
                    color-stop(21%, #ff8c7f),
                    color-stop(52%, #f99185),
                    color-stop(78%, #cf556c),
                    to(#b12a5b)
    );
    background-image: -webkit-linear-gradient(
                    left,
                    #ff8177 0%,
                    #ff867a 0%,
                    #ff8c7f 21%,
                    #f99185 52%,
                    #cf556c 78%,
                    #b12a5b 100%
    );
    background-image: linear-gradient(
                    to right,
                    #ff8177 0%,
                    #ff867a 0%,
                    #ff8c7f 21%,
                    #f99185 52%,
                    #cf556c 78%,
                    #b12a5b 100%
    );
}

.scrollbar-lady-lips::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-lady-lips::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-lady-lips::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(
                    linear,
                    left bottom,
                    left top,
                    from(#ff9a9e),
                    color-stop(99%, #fecfef),
                    to(#fecfef)
    );
    background-image: -webkit-linear-gradient(
                    bottom,
                    #ff9a9e 0%,
                    #fecfef 99%,
                    #fecfef 100%
    );
    background-image: linear-gradient(
                    to top,
                    #ff9a9e 0%,
                    #fecfef 99%,
                    #fecfef 100%
    );
}

.scrollbar-sunny-morning::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-sunny-morning::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-sunny-morning::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-linear-gradient(330deg, #f6d365 0%, #fda085 100%);
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
}

.scrollbar-rainy-ashville::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-rainy-ashville::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-rainy-ashville::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(
                    linear,
                    left bottom,
                    left top,
                    from(#fbc2eb),
                    to(#a6c1ee)
    );
    background-image: -webkit-linear-gradient(bottom, #fbc2eb 0%, #a6c1ee 100%);
    background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%);
}

.scrollbar-frozen-dreams::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-frozen-dreams::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-frozen-dreams::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(
                    linear,
                    left bottom,
                    left top,
                    from(#fdcbf1),
                    color-stop(1%, #fdcbf1),
                    to(#e6dee9)
    );
    background-image: -webkit-linear-gradient(
                    bottom,
                    #fdcbf1 0%,
                    #fdcbf1 1%,
                    #e6dee9 100%
    );
    background-image: linear-gradient(
                    to top,
                    #fdcbf1 0%,
                    #fdcbf1 1%,
                    #e6dee9 100%
    );
}

.scrollbar-warm-flame::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-warm-flame::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-warm-flame::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-linear-gradient(
                    45deg,
                    #ff9a9e 0%,
                    #fad0c4 99%,
                    #fad0c4 100%
    );
    background-image: linear-gradient(
                    45deg,
                    #ff9a9e 0%,
                    #fad0c4 99%,
                    #fad0c4 100%
    );
}

.scrollbar-night-fade::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-night-fade::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-night-fade::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(
                    linear,
                    left bottom,
                    left top,
                    from(#a18cd1),
                    to(#fbc2eb)
    );
    background-image: -webkit-linear-gradient(bottom, #a18cd1 0%, #fbc2eb 100%);
    background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%);
}

.scrollbar-spring-warmth::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-spring-warmth::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-spring-warmth::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(
                    linear,
                    left bottom,
                    left top,
                    from(#fad0c4),
                    to(#ffd1ff)
    );
    background-image: -webkit-linear-gradient(bottom, #fad0c4 0%, #ffd1ff 100%);
    background-image: linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%);
}

.scrollbar-winter-neva::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-winter-neva::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-winter-neva::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-linear-gradient(330deg, #a1c4fd 0%, #c2e9fb 100%);
    background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}

.scrollbar-dusty-grass::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-dusty-grass::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-dusty-grass::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-linear-gradient(330deg, #d4fc79 0%, #96e6a1 100%);
    background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
}

.scrollbar-tempting-azure::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-tempting-azure::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-tempting-azure::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-linear-gradient(330deg, #84fab0 0%, #8fd3f4 100%);
    background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}

.scrollbar-heavy-rain::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-heavy-rain::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-heavy-rain::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(
                    linear,
                    left bottom,
                    left top,
                    from(#cfd9df),
                    to(#e2ebf0)
    );
    background-image: -webkit-linear-gradient(bottom, #cfd9df 0%, #e2ebf0 100%);
    background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}

.scrollbar-amy-crisp::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-amy-crisp::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-amy-crisp::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-linear-gradient(330deg, #a6c0fe 0%, #f68084 100%);
    background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
}

.scrollbar-mean-fruit::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-mean-fruit::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-mean-fruit::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-linear-gradient(330deg, #fccb90 0%, #d57eeb 100%);
    background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
}

.scrollbar-deep-blue::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-deep-blue::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-deep-blue::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-linear-gradient(330deg, #e0c3fc 0%, #8ec5fc 100%);
    background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
}

.scrollbar-ripe-malinka::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-ripe-malinka::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-ripe-malinka::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-linear-gradient(330deg, #f093fb 0%, #f5576c 100%);
    background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%);
}

.scrollbar-cloudy-knoxville::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-cloudy-knoxville::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-cloudy-knoxville::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-linear-gradient(330deg, #fdfbfb 0%, #ebedee 100%);
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

.scrollbar-morpheus-den::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-morpheus-den::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-morpheus-den::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(
                    linear,
                    left bottom,
                    left top,
                    from(#30cfd0),
                    to(#330867)
    );
    background-image: -webkit-linear-gradient(bottom, #30cfd0 0%, #330867 100%);
    background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%);
}

.scrollbar-rare-wind::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-rare-wind::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-rare-wind::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(
                    linear,
                    left bottom,
                    left top,
                    from(#a8edea),
                    to(#fed6e3)
    );
    background-image: -webkit-linear-gradient(bottom, #a8edea 0%, #fed6e3 100%);
    background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%);
}

.scrollbar-near-moon::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-near-moon::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-near-moon::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(
                    linear,
                    left bottom,
                    left top,
                    from(#5ee7df),
                    to(#b490ca)
    );
    background-image: -webkit-linear-gradient(bottom, #5ee7df 0%, #b490ca 100%);
    background-image: linear-gradient(to top, #5ee7df 0%, #b490ca 100%);
}

.scrollbar-pink::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-pink::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-pink::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #ec407a;
}

.scrollbar-indigo::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-indigo::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-indigo::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #3f51b5;
}

.scrollbar-black::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-black::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-black::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #000;
}

.scrollbar-lady-lips::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-lady-lips::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-lady-lips::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(
                    linear,
                    left bottom,
                    left top,
                    from(#ff9a9e),
                    color-stop(99%, #fecfef),
                    to(#fecfef)
    );
    background-image: -webkit-linear-gradient(
                    bottom,
                    #ff9a9e 0%,
                    #fecfef 99%,
                    #fecfef 100%
    );
    background-image: linear-gradient(
                    to top,
                    #ff9a9e 0%,
                    #fecfef 99%,
                    #fecfef 100%
    );
}

.scrollbar-near-moon::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.scrollbar-near-moon::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-near-moon::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(
                    linear,
                    left bottom,
                    left top,
                    from(#5ee7df),
                    to(#b490ca)
    );
    background-image: -webkit-linear-gradient(bottom, #5ee7df 0%, #b490ca 100%);
    background-image: linear-gradient(to top, #5ee7df 0%, #b490ca 100%);
}

.bordered-pink::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border: 1px solid #ec407a;
}

.bordered-pink::-webkit-scrollbar-thumb {
    -webkit-box-shadow: none;
}

.bordered-indigo::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border: 1px solid #3f51b5;
}

.bordered-indigo::-webkit-scrollbar-thumb {
    -webkit-box-shadow: none;
}

.bordered-black::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border: 1px solid #000;
}

.bordered-black::-webkit-scrollbar-thumb {
    -webkit-box-shadow: none;
}

.square::-webkit-scrollbar-track {
    border-radius: 0 !important;
}

.square::-webkit-scrollbar-thumb {
    border-radius: 0 !important;
}

.thin::-webkit-scrollbar {
    width: 6px;
}

.modal {
    z-index: 10050 !important;
}

.text-black-imp {
    color: black !important;
}

.text-white-imp {
    color: white !important;
}

mdb-image-modal .ng-overlay {
    z-index: 100000 !important;
}

mdb-image-modal .ng-overlay .top-bar {
    z-index: 110000 !important;
}

mdb-image-modal .ng-overlay .ng-gallery-content {
    z-index: 100050 !important;
}

.navbar .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    cursor: pointer;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: '';
    background: no-repeat center center;
    background-size: 100% 100%;
}

.navbar .breadcrumb-dn {
    width: 50%;
}

ul li.dropdown a.dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar.scrolling-navbar {
    background-color: rgba(152, 158, 169, 0.65) !important;
}

.tox.tox-tinymce-aux,
.tox-fullscreen .tox.tox-tinymce-aux {
    z-index: 190000;
}

.card-wrapper .fa-repeat,
.card-wrapper .fa-undo {
    margin-top: auto !important;
}

.card-wrapper .back {
    padding: 0 !important;
}

.e-dlg-container {
    z-index: 10000 !important;
}

.e-control.e-grid-min-height .e-rowcell {
    line-height: 18px !important;
}

.tooltip.show {
    position: fixed !important;
    z-index: 100000000 !important;
}

.fa,
.fa-solid:before {
    position: relative !important;
    font-family: 'Font Awesome 6 Pro', serif !important;
    font-weight: 900 !important;
}

.fa-regular:before {
    position: relative !important;
    font-family: 'Font Awesome 6 Pro', serif !important;
    font-weight: 400 !important;
}

.fa-light:before {
    position: relative !important;
    font-family: 'Font Awesome 6 Pro', serif !important;
    font-weight: 300 !important;
}

.fa-duotone:before {
    position: relative !important;
    font-family: 'Font Awesome 6 Duotone', serif !important;
    font-weight: 900 !important;
}

.fa-duotone:before .fa-secondary {
    opacity: 0.8;
}

.fa-brands:before {
    position: relative !important;
    font-family: 'Font Awesome 6 Brands', serif !important;
    font-weight: 400 !important;
}

.skewed_bg {
    border-right: 3px solid #0382c7;
    border-radius: 0 40px 40px 0;
}

// SPINNER
.loader {
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background: linear-gradient(#dd1463, #2c7bde, #7ba41c);
    animation: animate 1.2s linear infinite;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader span {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(#dd1463, #2c7bde, #7ba41c);
}

.loader span:nth-child(1) {
    filter: blur(5px);
}

.loader span:nth-child(2) {
    filter: blur(10px);
}

.loader span:nth-child(3) {
    filter: blur(25px);
}

.loader span:nth-child(4) {
    filter: blur(50px);
}

.loader:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background: #fff;
    border: solid white 10px;
    border-radius: 50%;
}

.lds-circle {
    display: inline-block;
    transform: translateZ(1px);
}

.lds-circle > div {
    display: inline-block;
    width: 150px;
    height: 46px;
    background: url('/assets/img/logo-jon-sm.png');
    animation: lds-circle 8s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.disablecheckbox {
    pointer-events: none;
    opacity: 0.5;
}

.e-fixed {
    position: fixed;
}

.e-dialog .e-dlg-header {
    color: #434343;
    font-size: 17px;
    font-weight: normal;
}

.e-dlg-overlay {
    background-color: slategray;
    opacity: 0.5;
}

.e-excel-ascending,
.e-excel-descending,
.e-separator.e-excel-separator {
    display: none !important;
}

@keyframes lds-circle {
    0%,
    100% {
        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(1800deg);
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
        transform: rotateY(3600deg);
    }
}

.e-check:before {
    font-family: 'Font Awesome 6 Pro', serif !important;
    font-weight: 900;
    content: '\f00c' !important;
}

.e-times:before {
    font-family: 'Font Awesome 6 Pro', serif !important;
    font-weight: 900;
    content: '\f00d' !important;
}

.e-openfolder:before {
    font-family: 'Font Awesome 6 Pro', serif !important;
    font-weight: 900;
    content: '\f07c' !important;
}

.e-refresh:before {
    font-family: 'Font Awesome 6 Pro', serif !important;
    font-weight: 900;
    content: '\f021' !important;
}

.e-ruian:before {
    font-family: 'Font Awesome 6 Pro', serif !important;
    font-weight: 900;
    content: '\f6ff' !important;
}

.e-remove:before {
    font-family: 'Font Awesome 6 Pro', serif !important;
    font-weight: 900;
    content: '\f1f8' !important;
}

.e-revert:before {
    font-family: 'Font Awesome 6 Pro', serif !important;
    font-weight: 900;
    content: '\f1da' !important;
}

.e-copy:before {
    font-family: 'Font Awesome 6 Pro', serif !important;
    font-weight: 900;
    content: '\f0c5' !important;
}

.e-user-times:before {
    font-family: 'Font Awesome 6 Pro', serif !important;
    font-weight: 900;
    content: '\f235' !important;
}

.e-user-revert:before {
    font-family: 'Font Awesome 6 Pro', serif !important;
    font-weight: 900;
    content: '\e058' !important;
}

.e-report:before {
    font-family: 'Font Awesome 6 Pro', serif !important;
    font-weight: 900;
    content: '\f15c' !important;
}

.e-receipt:before {
    font-family: 'Font Awesome 6 Pro', serif !important;
    font-weight: 900;
    content: '\f543' !important;
}

.e-list:before {
    font-family: 'Font Awesome 6 Pro', serif !important;
    font-weight: 900;
    content: '\f022' !important;
}

.e-exchange:before {
    font-family: 'Font Awesome 6 Pro', serif !important;
    font-weight: 900;
    content: '\f0ec' !important;
}

#vacReports_dashboard .e-panel-content {
    overflow: hidden !important;
}

#vacReports_dashboard .e-panel-container {
    overflow: auto !important;
}

.e-input-group,
.e-input-group.e-control-wrapper,
.e-float-input,
.e-float-input.e-input-group,
.e-float-input.e-control-wrapper,
.e-float-input.e-input-group.e-control-wrapper {
    background: transparent;
}

.e-btn.e-danger {
    background-color: #ff3631 !important;
    border-color: #ff3631 !important;
    color: #ffffff !important;
}

.e-btn.e-danger:hover {
    background-color: #ff4f4b !important;
    color: #ffffff !important;
    border-color: #ff4f4b !important;
}

.e-btn.e-danger.e-outline {
    background-color: transparent !important;
    color: #ff3631 !important;
    border-color: #ff3631 !important;
}

.e-btn.e-danger.e-outline:hover {
    background-color: #ff4f4b !important;
    color: #ffffff !important;
    border-color: #ff4f4b !important;
}

.e-btn.e-warning {
    background-color: #ff8e38 !important;
    border-color: #ff8e38 !important;
    color: #ffffff !important;
}

.e-btn.e-warning:hover {
    background-color: #fd9647 !important;
    color: #ffffff !important;
    border-color: #fd9647 !important;
}

.e-btn.e-warning.e-outline {
    background-color: transparent !important;
    color: #ff8e38 !important;
    border-color: #ff8e38 !important;
}

.e-btn.e-warning.e-outline:hover {
    background-color: #fd9647 !important;
    color: #ffffff !important;
    border-color: #fd9647 !important;
}

.e-badge.e-badge-warning {
    background-color: #ff8e38 !important;
    border-color: #ff8e38 !important;
}

.e-badge.e-badge-danger {
    background-color: #ff3631 !important;
    border-color: #ff3631 !important;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
    background-color: #f5f5f5 !important;
    border-radius: 10px !important;
}

body::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    background-color: #f5f5f5 !important;
}

body::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
    background-image: -webkit-linear-gradient(
                    330deg,
                    #a1c4fd 0%,
                    #c2e9fb 100%
    ) !important;
    background-image: linear-gradient(
                    120deg,
                    #a1c4fd 0%,
                    #c2e9fb 100%
    ) !important;
}

.e-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
    background-color: #f5f5f5 !important;
    border-radius: 10px !important;
}

.e-content::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    background-color: #f5f5f5 !important;
}

.e-content::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
    background-image: -webkit-linear-gradient(
                    330deg,
                    #a1c4fd 0%,
                    #c2e9fb 100%
    ) !important;
    background-image: linear-gradient(
                    120deg,
                    #a1c4fd 0%,
                    #c2e9fb 100%
    ) !important;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-footer {
    display: block !important;
}

.e-grid .e-content {
    overflow-x: hidden !important;
}

.scroll_wrapper1,
.scroll_wrapper2 {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.scroll_wrapper1 {
    height: 10px;
    position: -webkit-sticky !important; /* sticky the element */
    position: sticky !important;
    top: 0 !important;
    z-index: 1 !important;
}

.scroll_wrapper2 {
    height: 800px;
}

.scroll_div {
    width: 4000px;
    height: 10px;
}

.grid_parent {
    width: 4000px;
    height: 800px;
    overflow: auto;
    margin: auto;
}

.scroll_wrapper2::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
    background-color: #f5f5f5 !important;
    border-radius: 16px !important;
}

.scroll_wrapper2::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
    background-color: #f5f5f5 !important;
}

.scroll_wrapper2::-webkit-scrollbar-thumb {
    border-radius: 16px !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
    background-image: -webkit-linear-gradient(
                    330deg,
                    #a1c4fd 0%,
                    #c2e9fb 100%
    ) !important;
    background-image: linear-gradient(
                    120deg,
                    #a1c4fd 0%,
                    #c2e9fb 100%
    ) !important;
}

.scroll_wrapper1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
    background-color: #f5f5f5 !important;
    border-radius: 16px !important;
}

.scroll_wrapper1::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
    background-color: #f5f5f5 !important;
}

.scroll_wrapper1::-webkit-scrollbar-thumb {
    border-radius: 16px !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
    background-image: -webkit-linear-gradient(
                    330deg,
                    #a1c4fd 0%,
                    #c2e9fb 100%
    ) !important;
    background-image: linear-gradient(
                    120deg,
                    #a1c4fd 0%,
                    #c2e9fb 100%
    ) !important;
}

.image-thumb-group {
    border: 1px solid #ffffff;
    border-radius: 5px;
    display: table;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.image-thumb-group img {
    cursor: pointer;
    width: 110px;
    height: 100px;
    padding: 5px;
    border-radius: 10px;
}

.alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 3rem;
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem;
}

.alert-primary {
    color: #084298;
    background-color: #cfe2ff;
    border-color: #b6d4fe;
}

.alert-primary .alert-link {
    color: #06357a;
}

.alert-secondary {
    color: #41464b;
    background-color: #e2e3e5;
    border-color: #d3d6d8;
}

.alert-secondary .alert-link {
    color: #34383c;
}

.alert-success {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
}

.alert-success .alert-link {
    color: #0c4128;
}

.alert-info {
    color: #055160;
    background-color: #cff4fc;
    border-color: #b6effb;
}

.alert-info .alert-link {
    color: #04414d;
}

.alert-warning {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
}

.alert-warning .alert-link {
    color: #523e02;
}

.alert-danger {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}

.alert-danger .alert-link {
    color: #6a1a21;
}

.alert-light {
    color: #636464;
    background-color: #fefefe;
    border-color: #fdfdfe;
}

.alert-light .alert-link {
    color: #4f5050;
}

.alert-dark {
    color: #141619;
    background-color: #d3d3d4;
    border-color: #bcbebf;
}

.alert-dark .alert-link {
    color: #101214;
}

.e-upload-files {
    .e-file-delete-btn {
        display: none !important;
    }
}

.e-grid tr.e-removed {
    background-color: rgba(255, 0, 0, 0.45);
    border-color: red;

    td {
        border-color: red;
    }
}

.e-footer-content .e-rte-dialog-minheight {
    min-height: 50px !important;
}

#maintaskText p {
    font-size: 14px !important;
    color: #000000 !important;
}

.text-ticket {
    font-size: 14px !important;
    color: black !important;
}

.fc .fc-daygrid-day-number {
    font-weight: 600;
    text-decoration: none;
    color: grey;
}

.fc-day {
    cursor: pointer;

    &:hover {
        .fc-daygrid-day-number {
            font-weight: 700;
            color: black;
        }
    }
}

.fc-col-header-cell-cushion {
    font-weight: 600;
    text-decoration: none;
    color: grey;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-md-left {
    @media (min-width: 576px) {
        text-align: left;
    }
}


//TEMP MDB COPLOR STYLES
.stylish-color-dark {
    background-color: #3e4551;
}

.font-weight-bold {
    font-weight: bold;
}

.mainTaksWrapper {
    .e-card-header-caption {
        min-width: 1em;
        flex-wrap: wrap;
    }
}
